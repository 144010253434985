import { Form, Formik } from 'formik';
import { useStaticQuery, graphql } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { func, shape, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { resolve } from '../../../helpers/urls';

import { Button } from '../button';
import { Checkbox, Input } from '../form';
import './newsletter.scss';

const STORAGE_KEY = 'newsletter_signup_email';

const bem = new BEMHelper({
  name: 'newsletter-signup',
});

const query = graphql`
  query {
    response: craftgql {
      contact: globalSet(handle: "contact") {
        ...on CraftGQL_contact_GlobalSet {
          privacyterms: contactPrivacyTermsPage {
            ...on CraftGQL_pages_pages_Entry {
              title
              slug: pageSlug
              departments: pageDepartments {
                ...on CraftGQL_departments_department_Entry {
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () => Yup.object().shape({
      email: Yup
        .string()
        .email(t('Your E-Mail address is invalid.'))
        .required(t('Your E-Mail address is required.')),
      privacy: Yup
        .boolean()
        .oneOf(
          [true],
          t('You need to accept our privacy terms.'),
        ),
    }),
    [],
  );
};

const useEmailFromStorage = () => useMemo(() => {
  try {
    return sessionStorage.getItem(STORAGE_KEY) || '';
  } catch (error) {
    return '';
  }
}, []);

const useSubmit = (endpoint, onSuccess) => {
  const { t } = useTranslation();

  return useCallback(
    async ({ email, privacy: [privacy] }, { setFieldError, setSubmitting }) => {
      try {
        const { result, msg } = await addToMailchimp(email, { privacy }, endpoint);
        if (result === 'error') {
          const isAlreadySubscribed = (/is already subscribed to list/).test(msg);

          if (!isAlreadySubscribed) {
            setFieldError('general', msg);
            setSubmitting(false);
            return;
          }
        }

        onSuccess();
        sessionStorage.setItem(STORAGE_KEY, email);
      } catch (error) {
        setFieldError(
          'general',
          t('An error occurred while sending the request. Please try again.'),
        );
        setSubmitting(false);
      }
    },
    [endpoint, onSuccess],
  );
};

export const SignUpForm = ({
  mailinglist,
  submit,
  onSuccess,
}) => {
  const { response } = useStaticQuery(query);
  const { contact } = response;
  const { t } = useTranslation();
  const onSubmit = useSubmit(mailinglist.endpoint, onSuccess);
  const email = useEmailFromStorage();
  const schema = useSchema();

  const privacytermsLink = resolve('pages:detail', {
    pageSlug: contact.privacyterms[0].slug,
    departmentSlug: contact.privacyterms[0].departments[0]?.slug,
  });

  return (
    <Formik
      initialValues={{ email }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        handleBlur,
      }) => (
        <Form {...bem()}>
          <div {...bem('details')}>
            <h2 {...bem('headline')}>{mailinglist.headline}</h2>
            <p {...bem('description')}>{mailinglist.description}</p>
            {errors.general && (<p {...bem('error')}>{parse(errors.general)}</p>)}
          </div>

          <div {...bem('form')}>
            <Input
              label={t('E-Mail')}
              type="email"
              name="email"
              id="newsletter-email"
              value={values.email}
              error={errors.email && touched.email && errors.email}
              onBlur={handleBlur}
              required
            />

            <Checkbox
              id="newsletter-privacy"
              name="privacy"
              error={errors.privacy && touched.privacy && errors.privacy}
              onBlur={handleBlur}
              required
            >
              <Trans i18nKey="terms_and_conditions">
                I have read the MedSkin Solutions Dr. Suwelack AG
                <a
                  href={privacytermsLink}
                  target="_blank"
                  rel="noopener"
                  {...bem('note__link')}
                >
                  privacy terms
                </a>
                and agree to them.
              </Trans>
            </Checkbox>

            <small {...bem('note')}>
              <Trans i18nKey="legal_note_mailchimp">
                We use Mailchimp as our marketing plattform.
                By clicking below to subscribe,
                you acknowledge that your inforamtion
                will be transfered to Mailchimp for processing.
                <a
                  href="https://mailchimp.com/legal/privacy/"
                  target="_blank"
                  rel="noopener"
                  {...bem('note__link')}
                >
                  Learn more about Malchimps privacy practices here
                </a>
              </Trans>
            </small>

            <Button
              {...bem('submit')}
              type="submit"
              title={submit.title}
              disabled={!isValid}
            >
              {submit.label}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SignUpForm.propTypes = {
  mailinglist: shape({
    headline: string.isRequired,
    description: string.isRequired,
    endpoint: string.isRequired,
  }).isRequired,
  submit: shape({
    label: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  onSuccess: func,
};

SignUpForm.defaultProps = {
  onSuccess: () => undefined,
};
